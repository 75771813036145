import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { MsalGuard } from '@azure/msal-angular';
import { PagenotfoundComponent } from '../../../goldstar-share/src/app/components/shared/pagenotfound/pagenotfound.component';
import { DashboardComponent } from './modules/components/dashboard/dashboard.component';
import { DirtySaveGuard } from './modules/guards/dirty-save.guard';
// import { AppDashboardModule } from './modules/app-dashboard.module';
// import { PageNotFoundComponent } from './modules/components/stand-alone/component-exporter';

const routes: Routes = [
	{ path: 'pagenotfound', component: PagenotfoundComponent, canActivate: [MsalGuard] },

	/* THE DEFAULT ROUTE IS DASHBOARD 
	   STOP LOADING APP DASHBOARD MODULE, INSTEAD BRING APP DASHBOARD MODULE AS PART OF APP
	*/
	{ path: 'logout', component: LogoutComponent },
	{
		path: '',
		canActivate: [MsalGuard, DirtySaveGuard],
		component: DashboardComponent,
		runGuardsAndResolvers: 'always',
		data: {},
		loadChildren: () => import('././modules/app-dashboard.module').then((m) => m.AppDashboardModule),
	},
	{ path: 'login', component: LoginComponent },

	{ path: 'logout/home', redirectTo: 'home' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
