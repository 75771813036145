import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './components/auth/auth.service';
import { ImpersonateUserComponent } from './components/impersonate/impersonate-user/impersonate-user.component';
import { AppState } from './models/classes';
import { ChatMessage, NavItem, ScreenSize } from './models/interfaces';
import { MenuGroupModel, NotificationReceiveInfoModel, SearchComponentType } from './models/models';
import { DashboardLayoutService } from './services/dashboard-layout.service';
import { GlobalKeyEventHandlerService } from './services/global-key-event-handler.service';
import { MenuService } from './services/menuService';
import { ScreenSizeObserverService } from './services/screen-size-observer.service';
import { UserInfoService } from './services/user-info.service';
import { chatAddedState, chatCollectionUpdateState, notificationAddedState, notificationCollectionUpdateState } from './store/store.selector';
import { map, Subscription, timer } from 'rxjs';
import { ChatService } from './services/chat/chat.service';
import { ErrorHandlingService } from '../../../goldstar-share/src/app/services/error-handling.service';
import { NotificationUpdateModel } from '../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/notification-update-model';
import { MarkChatMessageAsDelivered } from '../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { PermissionService } from '../../../goldstar-share/src/app/services/permission.service';
import { SessionService } from './services/session.service';
import { loadMessages } from 'devextreme/localization';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ScreenSizeObserverService],
})
export class AppComponent implements OnInit, OnDestroy {
	public static hideMenu: boolean = false;
	public screenSize!: ScreenSize;
	public showMenu: boolean = false;
	public showMenuBtn: boolean = false;
	public showFavoriteMenu: boolean = false;
	title = 'goldstar-internal';
	hasCustomDashboards: boolean = false;
	public isAuthenticated: boolean = false;
	panelOpenState: boolean = false;
	public userFavoriteMenuGroup!: MenuGroupModel;
	public showMenuBtnSpinner: boolean = false;
	public searchTerm!: string;
	public showDefaultSearchResult: boolean = false;
	public showSmallScreenSearchResult: boolean = false;
	public clearSearchValue!: boolean;
	public searchComponentType!: SearchComponentType;
	public logOut: string[] = ['log out'];
	public isImpersonate: boolean = false;

	//user permission flag's
	public hasUserImpersonatePermission: boolean = false;

	//#region  subscription
	public largeScreenSize$!: Subscription;
	public fullScreenSize$!: Subscription;
	public smallScreenSize$!: Subscription;
	public escapeKeyDownHandler$!: Subscription;
	public selectedAuthenticationState$!: Subscription;
	public userIdUpdatedState$!: Subscription;
	public menuLoadedStateS!: Subscription;
	public screenSizeChanged$!: Subscription;
	public escapeKeyDown$!: Subscription;
	public timerSubscription$!: Subscription;

	// Notification Subscriptions
	public notificationConnectSubscription$!: Subscription;
	public notificationCollectionUpdatesSubscription$!: Subscription;
	public notificationAddedSubscription$!: Subscription;
	public chatMessageCollectionUpdatedSubscription$!: Subscription;
	public chatMessageAddedSubscription$!: Subscription;

	public userName!: string;
	public matContainerStyle!: string;
	public showFiller: boolean = false;

	public notificationCount!: string;
	public chatMessageCount!: string;
	public pinUtilityMenu: boolean = false;
	public showChatComponent: boolean = false;
	public showNotificationComponent: boolean = false;

	@ViewChild('snav') sideNav!: MatSidenav;

	//#endregion
	@ViewChild(MatDrawer) drawer!: MatDrawer;
	constructor(
		private dashboardLayoutService: DashboardLayoutService,
		private changeDetectorRef: ChangeDetectorRef,
		public authService: AuthService,
		public router: Router,
		private escapeKeyDownHandler: GlobalKeyEventHandlerService,
		private screenSizeObserver: ScreenSizeObserverService,
		public dialog: MatDialog,
		window: Window
	) {
		this.notificationCount = '0';
		this.chatMessageCount = '0';
		this.showDefaultSearchResult = false;
		this.showSmallScreenSearchResult = false;
		this.hasCustomDashboards = dashboardLayoutService.DashboardLayouts.length > 0;

		// register to global key (escape) down event
		this.escapeKeyDownHandler.registerEvent(window.document.documentElement, 'keydown.escape');
	}

	ngOnInit(): void {
		this.showFavoriteMenu = false;
		this.showMenuBtn = false;
		this.screenSize = ScreenSize.XL;
		this.clearSearchValue = false;
		this.searchComponentType = SearchComponentType.default;
		this.matContainerStyle = 'mat-container-closed';

		//This will change the Cancel text on Column Chooser popup of devextreme data grid to Close
		loadMessages({
			en: {
				Cancel: 'Close',
			},
		});
	}

	ngAfterViewInit() {
		this.screenSizeObserver.subscribeFullScreenUpdate();
		this.screenSizeObserver.subscribeLargeScreenUpdate();
		this.screenSizeObserver.subscribeSmallScreenUpdate();
		this.screenSizeObserver.subscribeMobileScreenUpdate();
	}

	onClick() {
		if (this.showMenu) {
			// search menu is open and we need to close it
			this.showMenu = false;
			this.clearSearchValue = !this.clearSearchValue;
		} else {
			// menu is already closed and we need to open it
			this.showMenu = true;
			this.showDefaultSearchResult = false;
			this.clearSearchValue = !this.clearSearchValue;
		}
	}

	ngAfterViewChecked() {
		this.changeDetectorRef.detectChanges();
	}

	navItems: NavItem[] = [
		{
			//   displayName: "CRI Admin",
			//   expanded: true,
			//   route: 'mainpipelinedashboard',
			//   children: [
			//     {
			displayName: 'CRI Admin',
			iconName: '',
			route: 'mainpipelinedashboard',
			expanded: true,
			children: [
				{
					displayName: 'Users',
					iconName: '',
					route: 'cri/user',
				},
				{
					displayName: 'Organization',
					iconName: '',
					route: 'cri/communityorganization',
				},
				{
					displayName: 'Purpose of Service',
					iconName: '',
					route: 'cri/purposeofservice',
				},
				{
					displayName: 'Position Type',
					iconName: '',
					route: 'cri/positiontype',
				},
				{
					displayName: 'CRI Office Held',
					iconName: '',
					route: 'cri/officeheld',
				},
			],
		},
		{
			displayName: 'Training Admin',
			expanded: true,
			children: [
				{
					displayName: 'Training Registration',
					iconName: '',
					route: 'trainingregistration',
				},
				{
					displayName: 'Training List',
					iconName: '',
					route: 'traininglist',
				},
			],
		},

		{
			displayName: 'CRI Log',
			iconName: '',
			route: 'cri/log',
		},
		{
			displayName: 'Loan Officer Links',
			iconName: '',
			route: 'loanofficerlinks',
		},

		{
			displayName: 'Trainings',
			iconName: '',
			route: 'traininglistregistration',
		},
		//   ]
		// },
		// {
		//   displayName: 'Training Admin',
		//   expanded: true,
		//   children: [
		//      {
		//   displayName: 'Training Calendar',
		//   iconName: '',
		//   route: 'training'
		// },
		// {
		//   displayName: 'Trainings Admin',
		//   iconName: '',
		//   route: 'traininglist'
		// },

		//   ]
		// }
	];

	customNavItems: NavItem[] = [
		{
			displayName: 'Custom Dashboards',
			expanded: true,
			children: this.dashboardLayoutService.DashboardLayouts.filter((x) => !!x.name).map((x) => {
				return {
					displayName: x.name,
					route: 'customdashboard',
					customName: x.name,
				};
			}),
		},
	];

	onLogOut() {
		this.authService.logout();
	}
	onImpersonate() {
		this.openImpersonateDialog({});
	}
	openImpersonateDialog(data: any) {
		const dialogRef = this.dialog.open(ImpersonateUserComponent, {
			width: '450px',
			data: data,
		});

		dialogRef.afterClosed().subscribe(async (result: any) => {
			if (result.data != false) {
				this.isImpersonate = this.authService.isActiveImpersonation;
				this.showFavoriteMenu = false;
				this.router.navigate(['/dashboard/home']);
			}
		});
	}
	onStopUserImpersonation() {
		this.authService.stopImpersonation(this.authService.userId);
		this.showFavoriteMenu = false;
		this.isImpersonate = this.authService.isActiveImpersonation;
		this.router.navigate(['/dashboard/home']);
	}

	ngOnDestroy(): void {
		this.timerSubscription$.unsubscribe();
		this.largeScreenSize$.unsubscribe();
		this.fullScreenSize$.unsubscribe();
		this.smallScreenSize$.unsubscribe();
		this.escapeKeyDownHandler$.unsubscribe();
		this.selectedAuthenticationState$.unsubscribe();
		this.userIdUpdatedState$.unsubscribe();
		this.menuLoadedStateS.unsubscribe();
		this.screenSizeChanged$.unsubscribe();
		this.escapeKeyDown$.unsubscribe();

		// Notification subscription unsubscribe
		this.notificationConnectSubscription$.unsubscribe();
		this.notificationCollectionUpdatesSubscription$.unsubscribe();
		this.notificationAddedSubscription$.unsubscribe();
		this.chatMessageCollectionUpdatedSubscription$.unsubscribe();
		this.chatMessageAddedSubscription$.unsubscribe();
	}

	onScroll(): void {
		alert('Scrolled is called');
	}
}
