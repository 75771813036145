import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { ScreenSize, ScreenSizeInfo, ScreenSizeState } from '../models/interfaces';
import { ScreenSizeStateAction } from '../store/store.actions';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeNotifierService {

  constructor(private store: Store<AppState>) {  }

  public pushScreenSizeUpdateMessage(screenSize: ScreenSize){
    const screenSizeInfo: ScreenSizeInfo = {
      isChanged : true,
      screenSize: screenSize
    };
    const screenSizeState: ScreenSizeState = {
      screenSizeInfo: screenSizeInfo
    }
    this.store.dispatch(ScreenSizeStateAction (screenSizeState));
  }
}
