<!-- <h1 *ngIf="authService.isLoggedIn">Logging out...</h1>
<h1 *ngIf="!authService.isLoggedIn">
	You are logged out

	<br />
	<br />
	<br />

	<button mat-raised-button color="primary" type="button" (click)="login()">Log In</button>
</h1> -->

<section class="login-sec">
	<div class="container-fuild">
		<div class="row">
			<div class="col-md-8 p-0">
				<div class="login-banner"></div>
				<div class="login-data">
					<img src="../../../../assets/newlayout/Trb_Bank_Logo.png" />
					<h3>WELCOME BACK!</h3>
					<button (click)="login()">Login</button>
					<div class="bg-dots">
						<img src="../../../../assets/newlayout/dots-bg.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
