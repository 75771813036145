<!-- <div style="height: 100%">
	<div style="width: 100%; height: 10%; display: flex; flex-direction: column; background-color: #002b5c">
		<div style="display: flex; flex-direction: row; align-items: center; padding: 0 1rem 0 2rem">
			<a [routerLink]="['/home']">
				<img class="small-image" src="../../../assets/images/logo.svg" aria-label="logo" />
			</a>
			<div class="search-bar-width">
				<ngx-spinner *ngIf="showMenuBtnSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-fade" [fullScreen]="false" style="position: fixed; padding-left: 50px">
				</ngx-spinner>
				<button
					*ngIf="showMenuBtn"
					title="Menu"
					appBtnHover
					[btnHoverStyle]="'menu-btn-active-style'"
					[btnDefaultStyle]="'menu-btn-default-style'"
					[btnClickStyle]="'menu-btn-active-style'"
					(click)="onClick()"
				>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="6" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="6" y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="6" y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="12" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="12" y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
						<rect x="12" y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
					</svg>
					<span class="globalNav-0313">Menu</span>
				</button>
			</div>
			<ng-container [ngSwitch]="searchComponentType">
				<app-dashboard-search *ngSwitchCase="'default'" (onSearchTrigger)="onSearchClick($event)" (onClearSearch)="onClearSearch()" [clearSearchValue]="clearSearchValue">
				</app-dashboard-search>
				<app-dashboard-small-screen-search *ngSwitchCase="'adaptive'" (onSearch)="onSmallScreenSearchClick()"> </app-dashboard-small-screen-search>
				<div *ngSwitchDefault>default case</div>
			</ng-container>
			<span class="toolbar-spacer"></span>
			<button style="color: white" mat-button [matMenuTriggerFor]="userMenu">{{ userName }}</button>
			<mat-menu #userMenu="matMenu">
				<button *ngIf="!isImpersonate && hasUserImpersonatePermission" mat-menu-item (click)="onImpersonate()">Impersonate User</button>
				<button *ngIf="isImpersonate" mat-menu-item (click)="onStopUserImpersonation()">Stop User Impersonation</button>
				<button mat-menu-item (click)="onLogOut()">Log Out</button>
			</mat-menu>
			<mat-icon *ngIf="!pinUtilityMenu" [ngStyle]="{ color: 'white' }" [matBadge]="notificationCount" matBadgeColor="warn" color="primary" (click)="utilityMenuUnpin()">message</mat-icon>
		</div>
		<div>
			<ngx-spinner
				*ngIf="!showFavoriteMenu"
				bdColor="rgba(0, 0, 0, 0.8)"
				size="small"
				color="#fff"
				type="line-spin-fade"
				[fullScreen]="false"
				style="position: fixed; padding-left: 50px; margin-top: 10px"
			>
			</ngx-spinner>
			<app-tab
				*ngIf="showFavoriteMenu"
				[userFavoriteGroupModel]="userFavoriteMenuGroup"
				style="width: 100%"
				(onFavoriteMenuReordered)="onUserFavoriteMenuReordered($event)"
				(onMenuItemClicked)="onMenuItemClicked($event)"
			></app-tab>
		</div>
	</div>
	<div style="display: flex; height: 90%">
		<div style="width: 96%; height: 100%" [ngStyle]="pinUtilityMenu === true ? { width: '96%' } : { width: '100%' }">
			<mat-sidenav-container style="height: inherit" [style.marginTop.px]="0">
				<mat-sidenav-content style="height: inherit; overflow: unset" cdkScrollable>
					<ng-container *ngIf="showMenu" [ngSwitch]="searchComponentType">
						<app-dashboard-menu *ngSwitchCase="'default'" (routeToPage)="onMenuItemClicked($event)" (onMenuClose)="hideMenu()" (onMenuBookmarked)="onMenuSelectedForBookmark($event)">
						</app-dashboard-menu>
						<app-dashboard-small-screen-menu
							*ngSwitchCase="'adaptive'"
							(routeToPage)="onMenuItemClicked($event)"
							(onMenuBookmarked)="onMenuSelectedForBookmark($event)"
						></app-dashboard-small-screen-menu>
					</ng-container>
					<ng-container>
						<app-dashboard-search-result
							*ngIf="showDefaultSearchResult"
							[searchTerm]="searchTerm"
							(onMenuBookmarked)="onMenuSelectedForBookmark($event)"
							(routeToPage)="onMenuItemClicked($event)"
							(onCloseSearch)="onClearSearch()"
						>
						</app-dashboard-search-result>
						<app-dashboard-small-screen-search-result
							*ngIf="showSmallScreenSearchResult"
							(onCloseSearch)="onClearSearch()"
							(onMenuBookmarked)="onMenuSelectedForBookmark($event)"
							(routeToPage)="onMenuItemClicked($event)"
						>
						</app-dashboard-small-screen-search-result>
					</ng-container>
					<div class="content" style="height: inherit">
						<div class="breadcrumb-style" style="height: 6%">
							<app-ng-dynamic-breadcrumb [bgColor]="'transparent'" [lastLinkColor]="'#002b5c'" [fontSize]="'16px'"></app-ng-dynamic-breadcrumb>
						</div>
						<router-outlet></router-outlet>
					</div>
				</mat-sidenav-content>
				<mat-sidenav #snav fixedTopGap="56" position="end">
					<div *ngIf="showNotificationComponent" class="container" style="min-width: 400px; height: inherit">
						<div class="row" style="height: inherit">
							<div class="col-sm-12" style="height: inherit">
								<mat-tab-group animationDuration="0ms" style="height: inherit">
									<mat-tab label="Notification">
										<app-notification style="height: inherit !important"></app-notification>
									</mat-tab>
									<mat-tab label="Send Notification">
										<app-send-notification style="height: inherit !important"></app-send-notification>
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</div>
					<div *ngIf="showChatComponent" class="container" style="min-width: 400px; height: inherit">
						<div class="row" style="height: inherit">
							<div class="col-sm-12" style="height: inherit">
								<mat-tab-group animationDuration="0ms" style="height: inherit">
									<mat-tab label="Chat">
										<app-chat-list style="height: inherit !important"></app-chat-list>
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</div>
				</mat-sidenav>
			</mat-sidenav-container>
		</div>
		<div *ngIf="pinUtilityMenu" style="background-color: #002b5c; color: white; width: 4%; display: flex; flex-direction: row; justify-content: center">
			<div style="display: flex; flex-direction: column; justify-content: space-between; padding-top: 10px; padding-bottom: 10px; align-items: center">
				<div style="display: flex; flex-direction: column">
					<mat-icon [ngStyle]="{ color: 'white' }" [matBadge]="notificationCount" matBadgeColor="warn" color="primary" (click)="sideNavToggle('showNotificationComponent')">message</mat-icon>
					<div style="padding-top: 20px">
						<mat-icon [ngStyle]="{ color: 'white' }" [matBadge]="chatMessageCount" matBadgeColor="warn" color="primary" (click)="sideNavToggle('showChatComponent')">
							track_changes</mat-icon
						>
					</div>
					<img
						style="padding-top: 20px"
						(click)="sideNavToggle('showChatComponent')"
						src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/0lEQVR4nN3VsUrDQAAG4CKCk3YQ9BXc1M3Nx3F0F10dFSdBQXwAqfgMKgiiYnHVR9BahC7qJ4EbQry2l+Zc/OEgxyX/B0m4a7X+ddDGBs7xjI8wiutOWGtPUjyNHfSMzxu2i2dSyxdxrX6usJDySu5NnjvMjQJONM/xsPJVfGcAvrASAw7ky14MeMoIdGNAv3pXaS1pXko/BvQyAoMY0JUvjzFgPyNwGAOWM/2mRdZ/AQE50jy30fLSVvHQoPwTa0OBgMzjZkJgd2R5ZbvewnuN8gtMJQElaDYcKmd4wWsY1RTrM7XKx8DlnCYfNjWBATazFpeASyz9SXkAkj/mD5BXwrDN7lWAAAAAAElFTkSuQmCC"
					/>
				</div>
				<div>
					<mat-button-toggle-group #group="matButtonToggleGroup">
						<mat-button-toggle style="background: transparent" aria-label="Text align left">
							<mat-icon [ngStyle]="{ color: 'white' }" color="primary" (click)="utilityMenuPin()">swap_horizontal_circle</mat-icon>
						</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
		</div>
	</div>
</div> -->

<router-outlet></router-outlet>
