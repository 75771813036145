import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseLayoutComponent } from '../../shared/base-layout/base-layout.component';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends BaseLayoutComponent {
	constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: string, public elRef: ElementRef) {
		super(elRef);
	}

	ngOnInit(): void {}
	onNoClick(): void {
		this.dialogRef.close();
	}
}
