import { Component, ElementRef, OnInit } from '@angular/core';
import { BaseLayoutComponent } from '../../shared/base-layout/base-layout.component';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent extends BaseLayoutComponent {
	constructor(public authService: AuthService, public elRef: ElementRef, private router: Router) {
		super(elRef);
	}

	ngOnInit() {
		if (this.authService.isLoggedIn) this.authService.logout();
	}

	login() {
		this.router.navigate(['']);
	}
}
