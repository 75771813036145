import { Router } from '@angular/router';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, LoginStatus } from '../auth.service';
import { BaseLayoutComponent } from '../../shared/base-layout/base-layout.component';
//import { AuthService, LoginStatus } from '../../../auth/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseLayoutComponent {
	LoginFormgroup!: UntypedFormGroup;
	submitted = false;
	emailValidationCode!: string;
	showEmailValidationCode: boolean = false;

	showLogin: boolean = true;
	showForgotPassword: boolean = false;
	showForgotPasswordSubmit: boolean = false;
	loginResponse!: LoginStatus;

	hidePassword: boolean = true;

	constructor(private formbuilder: UntypedFormBuilder, private router: Router, private authService: AuthService, public dialog: MatDialog, public elRef: ElementRef) {
		super(elRef);
	}

	ngOnInit(): void {
		this.LoginFormgroup = this.formbuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			emailValidationCode: [''],
		});
	}
	forgotPassClick() {
		const dialogRef = this.dialog.open(ForgotPasswordComponent, {
			width: '450px',
		});
	}
	onForgotPass() {
		this.showLogin = false;
		this.showForgotPassword = true;
		this.showForgotPasswordSubmit = false;
	}

	onBackToLogin() {
		this.showLogin = true;
		this.showForgotPassword = false;
		this.showForgotPasswordSubmit = false;
	}

	async SignIn() {
		await this.authService.login('');
	}

	onEmailValidation() {
		// this.authService.confirmEmail(
		//   this.LoginFormgroup.controls.email.value,
		//   this.LoginFormgroup.controls.password.value,
		//   this.LoginFormgroup.controls.emailValidationCode.value
		// )
	}
}
