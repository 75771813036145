import { Injectable } from '@angular/core';
import { CustomDashboardLayout } from '../models/interfaces';

@Injectable({
	providedIn: 'root',
})
export class DashboardLayoutService {
	public DASHBOARD_LAYOUTS: string = 'dashboardLayouts';

	public DashboardLayouts: CustomDashboardLayout[] = [];

	constructor() {
		const dashboardLayoutAsString = localStorage.getItem(this.DASHBOARD_LAYOUTS);

		if (dashboardLayoutAsString) {
			this.DashboardLayouts = JSON.parse(dashboardLayoutAsString);
		}
	}
}
