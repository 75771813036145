import { Injectable, OnDestroy } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class GlobalKeyEventHandlerService{

  public keyDownSubject !: Subject<Window>;

  get onEscapeKeyDown$(): Observable<Window> {
    return this.keyDownSubject.asObservable();
  }

  constructor(private eventManager: EventManager) { 
    this.keyDownSubject = new Subject();
  }

  /*
    binds the specified event with the given html element
    @returns a event observable 
  */
  public registerEvent(element: HTMLElement, event: string){
    this.eventManager.addEventListener(element, event, this.onEscapeKeyDown.bind(this));
  }

  private onEscapeKeyDown(event: UIEvent){
    this.keyDownSubject.next(<Window>event.target);
  }

}
