import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

/*
Interceptor Purpose: This interceptor was made to make sure we are calling the proper API (Dev or Prod).  
                        The way we implenented Lambda adds 'Dev' or 'Prod' to the url which causes nswag
                        to produce two functions for every endpoint (one for dev and prod) named
                        {EndPoint} and {EndPoint}2.
*/

@Injectable()
export class DevProdInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log('request', request);
        // console.log('next', next);

        // let url = request.url;
        // url = url.replace('/prod//dev/', '/prod/');
        // request = request.clone({ url: url });

        // url = url.replace('//dev', '/dev');
        // url = url.replace('//prod', '/prod');
        // request = request.clone({ url: url });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {
                //     console.log('event--->>>', event);
                // }
                return event;
            }));
    }
}